export default class EvaluatorAssessmentDTO {
  constructor() {
    this.id = null;
    this.description = null;
    this.employeeEvaluatorId = null;
    this.evaluatorWeight = null;
    this.jobAssessmentId = null;
    this.skillAssessment = "NA";
    this.skillName = null;
    this.skillType = null;
    this.skillId = null;
    this.skillWeight = 100;
    this.weight = 100;
  }

  calculatePercent(skillWeight) {
    let totalPercent = 100;
    let totalWeight = this.evaluatorList.reduce((acc, item) => acc + item.weight, 0);
    let evaluatorTotalWeight = skillWeight * totalPercent;
    let evaluatorPercent = evaluatorTotalWeight / totalWeight;
    return isNaN(evaluatorPercent) ? 0 : parseInt(evaluatorPercent.toFixed(0));
  }
}
