import EvaluatorAssessmentDTO from "./EvaluatorAssessmentDTO";
import AssessmentSkillDTO from "./AssessmentSkillDTO";

export default class EvaluateeDTO {
  constructor() {
    this.evaluatorId = null;
    this.assessmentId = null;
    this.detailDtoList = [];
  }

  addSkills(topSkills = []) {
    this.detailDtoList = topSkills.map(item => {
      let newAssessment = new EvaluatorAssessmentDTO();
      newAssessment.jobAssessmentId = this.assessmentId;
      newAssessment.skillId = item.id;
      newAssessment.skillName = item.skillName;
      newAssessment.skillType = item.skillType;
      newAssessment.skillWeight = this.calculatePercent(newAssessment.weight);
      newAssessment.employeeEvaluatorId = this.evaluatorId;
      return newAssessment;
    });
    //this.totalSkillWeightFit();
    /*this.calculation(this.detailDtoList);*/
  }

  totalSkillWeightFit() {
    this.detailDtoList.reduce((acc, item) => acc + item.skillWeight, 0);
  }

  addSkillAssessment(skillAssessment) {
    return new Promise((resolve, reject) => {
      if (!(skillAssessment instanceof AssessmentSkillDTO)) {
        reject("Skill Assessment must be instance AssessmentSkill Class");
      } else {
        let newAssessment = new EvaluatorAssessmentDTO();
        newAssessment.jobAssessmentId = this.assessmentId;
        newAssessment.skillId = skillAssessment.skillId;
        newAssessment.skillName = skillAssessment.skill.skillName;
        newAssessment.skillAssessment = skillAssessment.skillAssessment;
        newAssessment.skillType = skillAssessment.skill.skillType;
        newAssessment.skillWeight = 100;
        newAssessment.employeeEvaluatorId = this.evaluatorId;
        this.detailDtoList.push(newAssessment);
        resolve(true);
      }
    });
  }

  removeSkillAssessment(skillAssessment) {
    return new Promise((resolve, reject) => {
      if (!(skillAssessment instanceof AssessmentSkillDTO)) {
        reject("Skill Assessment must be instance AssessmentSkill Class");
      } else {
        let findIndex = this.detailDtoList.map(item => item.skillId).indexOf(skillAssessment.skillId);
        if (findIndex !== -1) {
          this.detailDtoList.splice(findIndex, 1);
          resolve(true);
        } else {
          reject("Böyle bir veri bulunamadı");
        }
      }
    });
  }

  calculation(evaluatorList) {
    if (evaluatorList.length > 0)
      evaluatorList.forEach(item => (item.skillWeight = this.calculatePercent(item.weight)));
  }

  calculatePercent(skillWeight) {
    let totalPercent = 100;
    let totalWeight = this.detailDtoList.reduce((acc, item) => acc + item.weight, 0);
    let evaluatorTotalWeight = skillWeight * totalPercent;
    let evaluatorPercent = evaluatorTotalWeight / totalWeight;
    return isNaN(evaluatorPercent) ? 0 : parseInt(evaluatorPercent.toFixed(0));
  }
}
